//
// Site Header
//

// Nav
.site-header  {
  nav {
    width: 100%;
  }
}

//
// Offcanvas Nav
//
$navbar-height: 98px !default;
$offcanvas-transition: transform .3s ease-in-out, visibility .3s ease-in-out !default;

html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

body {
  padding-top: $navbar-height;
}

@media (max-width: 991.98px) {

  .offcanvas-collapse {
    position: fixed;
    top: $navbar-height;
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: $gray;
    @include transition($offcanvas-transition);
  }
  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(-100%);
  }
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, .75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-underline .nav-link {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: .875rem;
  color: #6c757d;
}

.nav-underline .nav-link:hover {
  color: #007bff;
}

.nav-underline .active {
  font-weight: 500;
  color: #343a40;
}

//
// Breadcrumbs
//
.breadcrumbs {
  .breadcrumb {
    margin-bottom: 0;
  }
  a {
    color: $black;
  }
}

//
// CTA links
//
$cta-link-transition: right .3s ease-in-out !default;
.cta-link {
  position: relative;
  font-weight: 500;
  &::after {
    position: absolute;
    top: 6px;
    right: -15px;
    width: 6px;
    height: 10px;
    content: "";
    background-image: url("../../../img/cta-arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    @include transition($cta-link-transition);
  }
  &:hover {
    &::after {
      right: -20px;
    }
  }
}

//
// Quotes
//
.testimonial {
  padding: 1em 0 0 1.5em;
  background-image: url("../../../img/quotes-trans-white.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 50px;
}
@include media-breakpoint-up(lg) {
  .large-testimonial {
    .testimonial {
      max-width: 75%;
      padding: 2em 0 0 6em;
      margin: 0 auto 1em;
      background-size: 125px;
      blockquote {
        margin-bottom: 1rem;
        line-height: 1.4;
      }
    }
  }
}

//
// Image Styles & Transforms
//

.transform-expand {
  transform: scale(1.1);
}

.transform-expand-right {
  transform: scale(1.1) translateX(20%);
}

.transform-expand-left {
  transform: scale(1.1) translateX(-20%);
}

.border-radius-lg {
  @include border-radius($border-radius-lg);
}

.box-shadow {
  box-shadow: $box-shadow;
}
.box-shadow-sm {
  box-shadow: $box-shadow-sm;
}
.box-shadow-lg {
  box-shadow: $box-shadow-lg;
}

//
// Backgrounds
//
.bg-fullwidth {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

//
// Carousel / Flickity
//

@media screen and (min-width: 1000px) {
  .flickity-viewport {
    margin: 0 -1%;
  }
  .carousel-cell {
    width: 33%;
    padding: 0 1%;
  }
  .flickity-prev-next-button.previous {
    left: -60px;
  }
  .flickity-prev-next-button.next {
    right: -60px;
  }
}

//
// Pages
//

// Home
.home {
  .hero {
    padding: 3em 0;
    @include media-breakpoint-up(lg) {
      padding: 6em 0 3em;
    }
    .home-hero-img {
      transform: scale(1.3) translateX(10%);
      @include media-breakpoint-up(lg) {
        transform: scale(1.5) translateX(20%);
      }
    }
  }
}

//
// SEA
//
.sea {
  .more-features {
    background-image: url("../../../img/sea-crosses-bg@2x.png");
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 150px;
    @include media-breakpoint-up(lg) {
      background-size: 250px;
    }
  }
}

//
// Footer
//
.site-footer {
  a {
    color: $body-color;
  }
  .footer-menu {
    li {
      margin-bottom: 1em;
    }
  }
}
